.bzj-map-main-wrap {
  /*0%开始，100%结束*/
  /*坐标系*/
  /*堤坝*/
  /*流量*/
}
.bzj-map-main-wrap .map-wrap .map-f {
  width: 934px;
  transition: all 2s linear;
}
.bzj-map-main-wrap .map-wrap .map {
  width: 800px;
  height: 635px;
  background-image: url(~@/assets/images/img_map_baizhangji@2x.png);
  background-size: cover;
  position: relative;
}
.bzj-map-main-wrap .map-wrap .tips {
  position: absolute;
}
.bzj-map-main-wrap .map-wrap .tips .tis-name {
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  white-space: nowrap;
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot {
  width: 46px;
  height: 31px;
  border: 1px solid #1CF8B7;
  border-radius: 50% 50%;
  position: relative;
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot-before {
  content: '';
  display: block;
  width: 46px;
  height: 31px;
  border-radius: 50% 50%;
  opacity: 0.7;
  background-color: #1CF8B7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 200ms;
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot-c {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 29px;
  height: 20px;
  border: 2px solid #1CF8B7;
  border-radius: 50% 50%;
  transform: translate(-50%, -50%);
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot-c .tips-foot-c-c {
  position: relative;
  left: 50%;
  top: 50%;
  width: 21px;
  height: 11px;
  background: #1CF8B7;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot-cylinder {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 22px;
  height: 150px;
  z-index: 99;
  box-sizing: border-box;
  border: 2px solid;
  border-bottom: 0;
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot-cylinder-val {
  width: 60px;
  position: relative;
  top: -2px;
  left: 28px;
  padding: 4px 0;
  background: linear-gradient(0deg, #313CFB, #0A14BC);
  border: 1px solid #5A63FF;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}
.bzj-map-main-wrap .map-wrap .tips .tips-foot-cylinder-val-l {
  left: -66px;
}
.bzj-map-main-wrap .map-wrap .tag-wrap {
  width: auto;
  position: absolute;
  right: -92px;
  bottom: -28px;
}
.bzj-map-main-wrap .map-wrap .tag-wrap .tag-num {
  font-size: 12px;
  color: #8de3f8;
  padding-left: 34px;
  margin-bottom: 2px;
}
.bzj-map-main-wrap .map-wrap .tag-wrap-l {
  padding: 4px;
  border: 2px solid #215AAF;
}
.bzj-map-main-wrap .map-wrap .tag-wrap-l li {
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
}
.bzj-map-main-wrap .map-wrap .tag-wrap-l li:last-child {
  margin-bottom: 0;
}
.bzj-map-main-wrap .map-wrap .tag-wrap-r {
  padding-top: 5px;
}
.bzj-map-main-wrap .map-wrap .tag-wrap-r li {
  margin-bottom: 5px;
  color: #8de3f8;
  font-size: 12px;
}
.bzj-map-main-wrap .map-wrap .tag-wrap-r li:last-child {
  margin-bottom: 0;
}
.bzj-map-main-wrap .water-supply-main-wrap {
  width: 936px;
  height: 95px;
  background-image: url(~@/assets/images/icon-line.png);
  background-size: cover;
  padding-left: 20px;
  padding-right: 35px;
  box-sizing: border-box;
}
.bzj-map-main-wrap .water-supply-main-wrap dl {
  color: #ffffff;
  margin-top: 23px;
  text-align: center;
  min-width: 144px;
}
.bzj-map-main-wrap .water-supply-main-wrap dl dt {
  font-size: 14px;
  color: #8de3f8;
  margin-bottom: 4px;
}
.bzj-map-main-wrap .water-supply-main-wrap dl dd span:first-child {
  font-family: "myfamily";
  font-size: 18px;
}
.bzj-map-main-wrap .water-supply-main-wrap dl dd span:first-child i {
  font-size: 30px;
}
.bzj-map-main-wrap .water-supply-main-wrap dl dd .unit {
  display: inline-block;
  font-size: 16px;
  margin-left: 8px;
}
.bzj-map-main-wrap .water-supply-main-wrap dl dd .unit::after {
  display: inline-block;
  content: "";
  font-size: 16px;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2);
  }
  78%,
  100% {
    opacity: 0;
  }
}
.bzj-map-main-wrap .c1 {
  left: 340px;
  bottom: 393px;
}
.bzj-map-main-wrap .c2 {
  left: 209px;
  bottom: 345px;
}
.bzj-map-main-wrap .c3 {
  left: 452px;
  bottom: 590px;
}
.bzj-map-main-wrap .c4 {
  left: 506px;
  bottom: 526px;
}
.bzj-map-main-wrap .c5 {
  left: 563px;
  bottom: 202px;
}
.bzj-map-main-wrap .c6 {
  left: 674px;
  bottom: 268px;
}
.bzj-map-main-wrap .c7 {
  left: 704px;
  bottom: 525px;
}
.bzj-map-main-wrap .c8 {
  left: 663px;
  bottom: 590px;
}
.bzj-map-main-wrap .c9 {
  left: 50px;
  bottom: 580px;
}
.bzj-map-main-wrap .c10 {
  left: 227px;
  bottom: 526px;
}
.bzj-map-main-wrap .c11 {
  left: 386px;
  bottom: 201px;
}
.bzj-map-main-wrap .c12 {
  left: 532px;
  bottom: 142px;
}
.bzj-map-main-wrap .c13 {
  left: 636px;
  bottom: 203px;
}
.bzj-map-main-wrap .c14 {
  left: 418px;
  bottom: 405px;
}
.bzj-map-main-wrap .dykeDam-wrap-container {
  height: 734px;
}
.bzj-map-main-wrap .dykeDam-wrap {
  height: 734px;
  padding-top: 20px;
}
.bzj-map-main-wrap .dykeDam-wrap-hd-item {
  margin-bottom: 79px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bzj-map-main-wrap .dykeDam-wrap-hd-item-h4 {
  width: 298px;
  height: 84px;
  background-image: url('~@/assets/images/img_kurongdata_bg_3@2x.png');
  background-size: cover;
}
.bzj-map-main-wrap .dykeDam-wrap-hd-item-h4 h4 {
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 74px;
}
.bzj-map-main-wrap .dykeDam-wrap-hd-item-num {
  color: #F7E14E;
  font-size: 18px;
  text-align: center;
}
.bzj-map-main-wrap .dykeDam-wrap-hd-item-num span {
  margin-left: 20px;
  font-size: 48px;
  display: inline-block;
  font-family: "myFamily";
}
.bzj-map-main-wrap .dykeDam-wrap-bd {
  width: 935px;
  height: 471px;
  position: relative;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .tips-wrap {
  position: absolute;
  font-size: 16px;
  color: #4B9EE3;
  font-weight: 500;
  z-index: 11;
  right: 112px;
  top: -5px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .tips-wrap .tip-item {
  padding-bottom: 5px;
  width: 160px;
  margin-bottom: 20px;
  border-bottom: 2px solid #4B9EE3;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .tips-wrap .tip-item span {
  font-size: 14px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .tips-wrap .tip-item.l1 {
  margin-left: 41px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .tips-wrap .tip-item.l2 {
  margin-left: 76px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .tips-wrap .tip-item.l3 {
  margin-left: 109px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('~@/assets/images/img_baizhangji_daba@2x.png');
  background-size: cover;
  z-index: 10;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .mark-line {
  position: absolute;
  left: 0;
  font-size: 18px;
  top: 18px;
  color: #4AD6FE;
  display: inline-block;
  padding-bottom: 17px;
  border-bottom: 4px dashed #4AD7FF;
  width: 425px;
  text-align: center;
  z-index: 9;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .wave-wrap {
  position: absolute;
  top: 9px;
  left: 1px;
  z-index: 8;
  width: 429px;
  height: 385px;
  overflow: hidden;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .wave-wrap .wave-svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .kedu {
  position: absolute;
  top: -9px;
  left: -27px;
  z-index: 10;
  margin-right: 9px;
  font-size: 14px;
  width: 30px;
  background-image: url('~@/assets/images/img_baizhangji_ruler@2x.png');
  background-size: cover;
  height: 483px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .kedu ul {
  text-align: center;
  margin-right: 8px;
}
.bzj-map-main-wrap .dykeDam-wrap-bd .kedu ul li {
  color: #6fd7e8;
  margin-bottom: 35px;
}
.bzj-map-main-wrap .fill {
  width: 100%;
  height: 100%;
}
.bzj-map-main-wrap .map-child-wrap,
.bzj-map-main-wrap .dykeDam-wrap,
.bzj-map-main-wrap .huanying-child-wrap {
  display: none;
  transition: all 1s linear 0s;
}
.bzj-map-main-wrap .show_map {
  display: block;
}
.bzj-map-main-wrap .hide_map {
  display: none;
}
.bzj-map-main-wrap .liuliang-wrap-l {
  width: 166px;
  height: 95px;
  background-image: url(~Image/img_liuliang_bg01@2x.png);
  background-size: cover;
  margin-left: 10px;
  padding: 0;
}
.bzj-map-main-wrap .liuliang-wrap-l dl {
  margin: auto;
  margin-top: 23px;
}
.bzj-map-main-wrap .liuliang-wrap-r {
  width: 770px;
  height: 95px;
  background-image: url(~Image/img_liuliang_bg02@2x.png);
  background-size: cover;
}
.bzj-map-main-wrap .tit-water-wrap {
  height: 75px;
  width: 32px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 9px 7px;
  border-top: 4px solid #6FD7E8;
  letter-spacing: 2px;
  background-image: linear-gradient(to right, rgba(29, 72, 134, 0.7), rgba(29, 72, 134, 0.1));
}
.bzj-map-main-wrap .water-info-wrap .water-supply-wrap {
  margin: auto;
  width: 986px;
}
.bzj-map-main-wrap .huanying-child-wrap {
  margin-right: 10px;
  width: 980px !important;
  height: 734px;
  box-shadow: 0 0 20px rgba(0, 248, 255, 0.2);
  background-image: url(~Image/img_huanying@2x.png);
  background-size: cover;
}
.bzj-map-main-wrap .show-child-wrap {
  width: 986px;
  height: 680px;
}
.huanying-child-wrap-img {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 100% !important;
  background-size: cover;
  z-index: 9999;
  transition: all 0.2s linear;
  opacity: 1;
  height: 100%;
}
.huanying-child-wrap-img img {
  width: 100%;
}
.huanying-child-wrap-img.hidden {
  opacity: 0;
  z-index: -99;
}
.huanying-child-wrap-img .slider-bg {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.huanying-child-wrap-img .slider-bg .slider-list-wrap {
  width: 56%;
  height: 74%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.huanying-child-wrap-img .slider-bg .slider-list-wrap img {
  width: 100%;
}
.huanying-child-wrap-img .slider-bg .slider-list-tit {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 100px;
  color: #FFC000;
  letter-spacing: 22px;
  white-space: nowrap;
  word-break: break-all;
  width: auto;
  text-shadow: 5px 2px 6px rgba(0, 0, 0, 0.6);
}
