.screen-video-wrap {
  background-color: rgba(0, 0, 0, 0.6);
}
.screen-video-wrap .ant-spin-container {
  height: 100%;
}
.screen-video-wrap .ant-spin-nested-loading {
  height: 100%;
}
.screen-video-wrap .ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
.screen-image-wrap {
  display: flex;
  justify-content: center;
}
.screen-image-wrap .ant-spin-container {
  display: flex;
  align-items: center;
}
.screen-image-wrap .screen-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.screen-image-wrap .loading-wrap {
  width: 200px;
  height: 200px;
}
