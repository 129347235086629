#root {
  display: flex;
}
#root,
.container-wrap {
  width: 100%;
  min-height: 100%;
}
.container-wrap {
  background-image: url(~@/assets/images/img_bg_1@2x.png);
  background-size: cover;
}
.container-wrap .header-wrap {
  width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.container-wrap .header-wrap-bg {
  width: 100%;
  height: 64px;
  background-image: url(~@/assets/images/img_title@2x.png);
  background-size: contain;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}
.container-wrap .header-wrap-bg-2 {
  background-image: url(~@/assets/images/img2.png);
}
.container-wrap .header-wrap-bg-3 {
  background-image: url(~@/assets/images/img_title_yueshui@2x.png);
}
.container-wrap .container-bd-wrap {
  width: 1920px;
  height: 987px;
  margin-left: auto;
  margin-right: auto;
}
.container-wrap .container-left-wrap {
  height: 100%;
}
.container-wrap-bg-1 {
  background-image: url(~@/assets/images/img_bg_1@2x.png);
}
.container-wrap-bg-2 {
  background-image: url(~@/assets/images/img_bg_2.png);
}
.container-wrap-bg-3 {
  background-image: url(~@/assets/images/img_bg_1@2x.png);
}
.container-right-wrap {
  position: relative;
}
